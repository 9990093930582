import { Button, Input, Select } from "native-base";
import Wrapper from "../../components/Wrapper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from "../../utils/env";
import { baseApi } from "../../utils/api";
import { Image } from "native-base"
import Modal from "../../components/Modal/Modal"
function EditarUsuario() {
	const [form, setForm] = useState<any>({
		username: "",
		password: "",
		email: "",
		active: true,
		phone: "",
		name: "",
		image: null,
		roles:[2]
	});
	const [image, setImage] = useState<any>(null);
	const [retypePassword, setRetypePassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
		const [urlImage, setUrlImage] = useState<any>("");
	const [imageModalVisible, setImageModalVisible] = useState(false);
	const navigate = useNavigate();
	const params = useParams()
	useEffect(()=>{
		loadingResource()
	},[])
	const loadingResource = async () => {
		try {
			const { data } = await baseApi.get(`${API_URL}/user/${params.id}`, {
				headers: {
					"Content-Type": "multipart/form-data",
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			});
			setForm({...data, roles: data.userRole.map((v:any)=>v.role_id), password: ""});
		} catch (error) {
			if(__DEV__){
				  console.log(error);
			  }
			
		}
	}
	const createUser = async () => {
		if (form.password !== retypePassword) {
			alert("Las contraseñas no coinciden");
			return;
		}
		try {
			setIsLoading(true)
			var newUser = new FormData();
			newUser.append("username", form.username);
			newUser.append("email", form.email);
			newUser.append("active", form.active as unknown as string);
			newUser.append("phone", form.phone);
			newUser.append("name", form.name);
			newUser.append("roles", JSON.stringify(form.roles));
			if(image !== null){
				newUser.append("image", image!, image ? image.name : "");
			}

			const { data } = await baseApi.put(`${API_URL}/auth/update/${params.id}`, newUser, {
				headers: {
					"Content-Type": "multipart/form-data",
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			});
			navigate('/usuarios');
		} catch (error) {
			if(__DEV__){
				  console.log(error);
			  }
		}
		setIsLoading(false)
	}
	const onSelectedRole = (value: any) => {
		if (value === "") {
		  return;
		}
		// Validar si hay un rol diferente a "Usuario" en form.userRole
		const hasDifferentRole = form.userRole.some(
		(userRole: any) => userRole.role.name !== "Usuario"
		);
	
		if (hasDifferentRole) {
		alert("El usuario ya tiene un rol asignado.");
		return;
		}
		// en form..userRole rd un array de roles reocorremos los roles 
		setForm((prevForm: any) => ({
		  ...prevForm,
		  roles: [parseInt(value)]
		}));
	};
	useEffect(()=>{
		console.log(form)
	},[form.roles])
	return (
		<Wrapper title="Editar Usuario" subtitle="">
		<Modal modalVisible={imageModalVisible} setModalVisible={setImageModalVisible} children={(
				<Image alt="img" source={{uri: urlImage}} width={'400px'} height={'400px'} />
			)} title="Ver Imagen" childrenFooter={[]}/>
			<div className="page-wrapper">
				<div className="page-content">
					<div className="row">
						<div className="col-xl-12 mx-auto">
							<hr />
							<div className="card">
								<div className="card-body">
									<form className="row g-3">
										<div className="col-md-4 mb-3">
											<label className="form-label">Nombres:</label>
											<Input
												value={form.username}
												onChangeText={(text) => setForm({ ...form, username: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-8 mb-3">
											<label className="form-label">Apellidos:</label>
											<Input
											value={form.name}
											onChangeText={(text) => setForm({ ...form, name: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label">Email:</label>
											<Input
											value={form.email}
											onChangeText={(text) => setForm({ ...form, email: text })}
												type="email"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-3 mb-3">
											<label className="form-label">Telefono:</label>
											<Input
											value={form.phone}
											onChangeText={(text) => setForm({ ...form, phone: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-3 mb-3">
											<label className="form-label">Foto del Usuario:</label>

											<input
												onChange={(event: any) =>
													setImage(event.target.files[0])
												}
												type="file"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label">Foto actual:</label>
											<div onClick={() => {
												setUrlImage(form.image === null ? "/logo.webp" : form.image.url)
												setImageModalVisible(true)
											}}>
												<Image  alt="user foto" width={200} height={200} source={{uri: form.image === null ? "/logo.webp" : form.image.url}} />
											</div>
										</div>
										{/* <div className="col-md-6 mb-3">
											<label className="form-label">Contraseña:</label>
											<Input
												value={form.password}
												onChangeText={(text) => setForm({ ...form, password: text })}
												type="password"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label">Repetir Contraseña:</label>
											<Input
											value={retypePassword}
											onChangeText={(text) => setRetypePassword(text)}
												type="password"
												className="form-control"
												placeholder=""
											/>
										</div> */}
										<div className="col-md-3 mb-3">
											<label htmlFor="estado" className="form-label">
												Estado
											</label>
											<Select nativeID="estado" onValueChange={(value)=> setForm({...form, active: value as unknown as boolean})} selectedValue={form.active.toString()}>
												<Select.Item value="" label="Escoger..."/>
												<Select.Item value="true" label="Activo"/>
												<Select.Item value="false" label="No Activo"/>
											</Select>
										</div>
										<div className="col-md-3 mb-3">
											<label htmlFor="sadmin" className="form-label">
												Super Administrador
											</label>
											<Select nativeID="sadmin" onValueChange={onSelectedRole} selectedValue={form.roles[0].toString()}>
												<Select.Item value="" label="Escoger..."/>
												<Select.Item value="2" label="No"/>
												<Select.Item value="1" label="Si"/>
											</Select>
											
										</div>
										<div className="col-md-12 mb-3 d-flex justify-content-center">
											<Button
											style={{marginRight: 10,
												paddingHorizontal: 20,
												paddingVertical: 10,
												borderRadius: 8,
												backgroundColor: '#0d6efd',
												width:'50%'
											  }}	
											  _text={{
												color: '#fff',
												fontWeight: 'bold',
												fontSize: 16,
												marginBottom: 1,
											  }}
											isLoading={isLoading} onPress={()=>createUser()}>Editar Usuario</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}
export default EditarUsuario;
